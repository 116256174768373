import { createSlice } from "@reduxjs/toolkit";
import { getKpiProducts, getKpiSummary, getTimeAverages, getSummaryTotals, getSummaryOrders, getSummaryHours, getSummaryClients } from "../../api/Kpis";

export const kpisSlice = createSlice({
  name: "kpis",
  initialState: {
    summary: [],
    products: [],
    timeAverages: [],
    summaryTotals:[],
    summaryOrders:[],
    summaryHours:[],
    summaryClients:[],
  },
  reducers: {
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setTimeAverages: (state, action) => {
      state.timeAverages = action.payload;
    },
    setSummaryTotals: (state,action) => {
      state.summaryTotals = action.payload
    },
    setSummaryOrders: (state,action) => {
      state.summaryOrders = action.payload
    },
    setSummaryHours: (state,action) => {
      state.summaryHours = action.payload
    },
    setSummaryClients: (state,action) => {
      state.summaryClients = action.payload
    }
  },
});

const { setSummary, setProducts, setTimeAverages,setSummaryTotals,setSummaryOrders,setSummaryHours,setSummaryClients } = kpisSlice.actions;

export const getKpiSummaryThunk = (data) => async (dispatch) => {
  try {
    const response = await getKpiSummary(data);
    if (!response.error && response.status === 200) {
      console.log(response.data);
      dispatch(setSummary(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getKpiProductsThunk = (data) => async (dispatch) => {
  try {
    const response = await getKpiProducts(data);
    if (!response.error && response.status === 200) {
      dispatch(setProducts(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getTimeAveragesThunk = (data) => async (dispatch) => {
  try {
    const response = await getTimeAverages(data);
    if (!response.error && response.status === 200) {
      dispatch(setTimeAverages(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getSummaryTotalsThunk = (data) => async (dispatch) => {
  try {
    const response = await getSummaryTotals(data) 
    if(!response.error && response.status === 200){
      dispatch(setSummaryTotals(response.data))
      return {
        status:"success"
      }
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getSummaryOrdersThunk = (data) => async (dispatch) => {
  try {
    const response = await getSummaryOrders()
    if(!response.error && response.status === 200){
      dispatch(setSummaryOrders(response.data))
      return {
        status:"success"
      }
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}

export const getSummaryHoursThunk = (data) => async (dispatch) => {
  try {
    const response = await getSummaryHours(data)
    if(!response.error && response.status === 200){
      dispatch(setSummaryHours(response.data))
      return {
         status:"success"
      }
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}

export const getSummaryClientsThunk = (data) => async (dispatch) => {
  try {
    const response = await getSummaryClients(data)
    if(!response.error && response.status === 200){
      dispatch(setSummaryClients(response.data))
      return {
         status:"success"
      }
    }
    return {
      status: "error",
      type: "unknown",
    };
  } catch (error) {
    return {
      status: "error",
      type: "unknown",
    };
  }
}

export const selectSummary = (state) => state.kpis.summary;
export const selectProductsKpi = (state) => state.kpis.products;
export const selectTimesAveragesKpi = (state) => state.kpis.timeAverages;
export const selectSummaryTotals = (state) => state.kpis.summaryTotals;
export const selectSummaryOrders = (state) => state.kpis.summaryOrders;
export const selectSummaryHours = (state) => state.kpis.summaryHours;
export const selectSummaryClients = (state) => state.kpis.summaryClients

export default kpisSlice.reducer;
