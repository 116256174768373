import { Button, Form, Select } from "antd";
import styles from "../styles/Content.module.css";
import cx from "classnames";
const TopProducts = ({ branches, products, isAdmin }) => {
  const data = [
    {
      sucursal: "Las Cascadas",
      score: 1,
      product: "Combo 10 Alas o Chunks",
      amount: 25,
    },
    {
      sucursal: "Las Cascadas",
      score: 1,
      product: "Combo 10 Alas ",
      amount: 25,
    },
    {
      sucursal: "Las Cascadas",
      score: 1,
      product: "Combo 10 Chunks",
      amount: 25,
    },
  ];
  return (
    <div className={styles.cardContent}>
      {isAdmin && (
        <Form layout="inline">
          <Form.Item>
            <Select
              className={styles.select}
              size="large"
              placeholder="Todas las sucursales"
              showSearch
              optionFilterProp="label"
              options={branches.map((item) => {
                return { value: item.id, label: item.zone };
              })}
            />
          </Form.Item>
          <Button type="primary" size="large">
            Filtrar
          </Button>
        </Form>
      )}

      <br />
      <div className={styles.containerTable}>
        <span className={styles.headerItemTable}>Sucursal</span>
        <span className={styles.headerItemTable}>Posición</span>
        <span className={styles.headerItemTable}>Producto</span>
        <span
          className={cx(styles.headerItemTable, styles.lastHeaderItemTable)}
        >
          Cantidad
        </span>
        {products?.info?.map((item) => (
          <>
            <span className={styles.bodyCellTable}>
              <p>{item.branch}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.quantity}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.product}</p>
            </span>
            <span className={styles.bodyCellTable}>
              <p>{item.quantity}</p>
            </span>
          </>
        ))}
        {}
      </div>
    </div>
  );
};

export default TopProducts;
