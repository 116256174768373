import { Button, Card, DatePicker, Divider } from "antd";
import styles from "../styles/Content.module.css";
import { ReactComponent as UserLocked } from "../../../assets/icons/user-locked.svg";
import { ReactComponent as UserTag } from "../../../assets/icons/user-tag.svg";
import { ReactComponent as Buffalo } from "../../../assets/icons/buffalo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";

const CustomerTracking = ({summaryClients,handleRangesDates}) => {
  return (
    <div className={styles.cardContent}>
      <div className={styles.itemDatePicker}>
        <DatePicker.RangePicker onChange={handleRangesDates} />
      </div>
      <div className={styles.totalOverview}>
        <Card hoverable className={styles.antCard}>
          <div>
            <UserLocked />
            <p className={styles.number}>2,266</p>
            <p className={styles.label}>USUARIOS REGISTRADOS</p>
          </div>
        </Card>
        <Card hoverable className={styles.antCard}>
          <UserTag />
          <p className={styles.number}>1,035</p>
          <p className={styles.label}>USUARIOS ACTIVOS</p>
        </Card>
        <Card hoverable className={styles.antCard}>
          <Buffalo />
          <p className={styles.number}>1,582</p>
          <p className={styles.label}>ÓRDENES REALIZADAS</p>
        </Card>
      </div>
      <div className={styles.containerIndicators}>
        <div className={styles.indicatorOverview}>
          <span>
            <span className={styles.amountPercent}>
              <p className={styles.amount}>11</p>
              <ArrowUp />
              <p className={styles.percentSuccess}>76%</p>
            </span>
            <p className={styles.indicator}>CLIENTES NUEVOS</p>
          </span>
          <Button type="default" className={styles.buttonListClient}>
            LISTADO DE CLIENTES
          </Button>
        </div>
        <Divider type="vertical" className={styles.divider} />
        <div className={styles.indicatorOverview}>
          <span>
            <span className={styles.amountPercent}>
              <p className={styles.amount}>5</p>
              <ArrowDown />
              <p className={styles.percentFail}>8%</p>
            </span>
            <p className={styles.indicator}>SEGUNDO PEDIDO</p>
          </span>
          <Button type="default" className={styles.buttonListClient}>
            LISTADO DE CLIENTES
          </Button>
        </div>
        <Divider type="vertical" className={styles.divider} />
        <div className={styles.indicatorOverview}>
          <span>
            <span className={styles.amountPercent}>
              <p className={styles.amount}>3</p>
              <ArrowDown />
              <p className={styles.percentFail}>12%</p>
            </span>
            <p className={styles.indicator}>TERCER PEDIDO</p>
          </span>
          <Button type="default" className={styles.buttonListClient}>
            LISTADO DE CLIENTES
          </Button>
        </div>
        <Divider type="vertical" className={styles.divider} />

        <div className={styles.indicatorOverview}>
          <span>
            <span className={styles.amountPercent}>
              <p className={styles.amount}>2</p>
              <ArrowDown />
              <p className={styles.percentFail}>18%</p>
            </span>
            <p className={styles.indicator}>CUARTO PEDIDO</p>
          </span>
          <Button type="default" className={styles.buttonListClient}>
            LISTADO DE CLIENTES
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerTracking;
