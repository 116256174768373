import React, { useCallback, useEffect, useState } from "react";
import KpisUI from "./components/KpisUI";
import { useDispatch, useSelector } from "react-redux";
import {
  getKpiProductsThunk,
  getKpiSummaryThunk,
  getSummaryClientsThunk,
  getSummaryHoursThunk,
  getSummaryOrdersThunk,
  getSummaryTotalsThunk,
  getTimeAveragesThunk,
  selectProductsKpi,
  selectSummary,
  selectSummaryClients,
  selectSummaryHours,
  selectSummaryOrders,
  selectSummaryTotals,
  selectTimesAveragesKpi,
} from "../../redux/slices/kpis";
import { selectUser } from "../../redux/slices/user";
import { message } from "antd";
import moment from "moment";
import { getAllBranches, selectBranches } from "../../redux/slices/branches";
const Main = () => {
  const [activeCard, handleActiveCard] = useState({ key: 1, label: "RESUMEN" });
  const [activeDate, handleActiveDate] = useState("0");
  const summary = useSelector(selectSummary);
  const products = useSelector(selectProductsKpi);
  const timeAverages = useSelector(selectTimesAveragesKpi);
  const summaryTotals = useSelector(selectSummaryTotals);
  const summaryOrders = useSelector(selectSummaryOrders);
  const summaryHours = useSelector(selectSummaryHours);
  const summaryClients = useSelector(selectSummaryClients);
  const user = useSelector(selectUser);
  const branches = useSelector(selectBranches);
  const isAdmin = user.admin === 1;
  const dispatch = useDispatch();
  const handleActiveDay = async (day) => {
    const data = {
      branch_id: user.branch,
      user_id: user.id,
      filter_val: day,
      filter_type: "day",
    };
    switch (activeCard.key) {
      case 1:
        const responseSummary = await dispatch(getKpiSummaryThunk(data));
        console.log(responseSummary);
        if (responseSummary.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 2:
        const responseProducts = await dispatch(getKpiProductsThunk(data));
        console.log(responseProducts);
        if (responseProducts.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 3:
        const responseAverageTimes = await dispatch(getTimeAveragesThunk(data));
        console.log(responseAverageTimes);
        if (responseAverageTimes.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 6:
        const responseSummaryOrders = await dispatch(
          getSummaryOrdersThunk(data)
        );
        console.log(responseSummaryOrders);
        if (responseSummaryOrders.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      default:
        break;
    }

    handleActiveDate(day);
  };
  const handleRangesDates = async (dates) => {
    const data = {
      branch_id: user.branch,
      user_id: user.id,
      filter_val: `${moment(dates[0]).format("YYYY/MM/DD")}-${moment(
        dates[1]
      ).format("YYYY/MM/DD")}`,
      filter_type: "range",
    };
    switch (activeCard.key) {
      case 1:
        const responseSummary = await dispatch(getKpiSummaryThunk(data));
        console.log(responseSummary);
        if (responseSummary.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 2:
        const responseProducts = await dispatch(getKpiProductsThunk(data));
        console.log(responseProducts);
        if (responseProducts.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 3:
        const responseAverageTimes = await dispatch(getTimeAveragesThunk(data));
        console.log(responseAverageTimes);
        if (responseAverageTimes.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 6:
        const responseSummaryOrders = await dispatch(
          getSummaryOrdersThunk(data)
        );
        console.log(responseSummaryOrders);
        if (responseSummaryOrders.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      case 8:
        const responseSummaryClients = await dispatch(
          getSummaryClientsThunk(data)
        );
        console.log(responseSummaryClients);
        if (responseSummaryClients.status !== "success") {
          message.error("¡Hubo un problema!");
        }
        break;
      default:
        break;
    }
  };
  const initialFetch = useCallback(async () => {
    const response = await dispatch(getAllBranches(user.id));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const onFinishFilterTotalOverview = async (values) => {
    values.year = values.year ? moment(values.year).get("year") : null;
    values.user_id = user.id;
    console.log(values);
    const data = {
      user_id: user.id,
      year:  values.year,
      branch_id: isAdmin ? values.location : user.branch,
    };

    const response = await dispatch(getSummaryTotalsThunk(data));
    console.log(response);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const onFinishFilterDate = async (values) => {
    values.date = values.date ? moment(values.date).get("date") : null;
    console.log(values);
    const data = {
      user_id: user.id,
      date: values.date,
      branch_id: isAdmin ? values.location : user.branch,
    };

    const response = await dispatch(getSummaryHoursThunk(data));
    console.log(response);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const initialFetchKpis = useCallback(() => {
    console.log(activeCard);

    switch (activeCard.key) {
      case 1:
        handleActiveDay("0");
        break;
      case 2:
        handleActiveDay("0");
        break;
      case 3:
        handleActiveDay("0");
        break;
      case 4:
        break;
      case 5:
        onFinishFilterTotalOverview({ date: moment(),location:user.branch });
        break;
      case 6:
        handleActiveDay("7");
        break;
      case 7:
        onFinishFilterDate({ date: moment(),location:user.branch });
        break;
      case 8:
        handleRangesDates([
          moment().format("YYYY/MM/DD"),
          moment().format("YYYY/MM/DD"),
        ]);
        break;
      default:
        break;
    }
  }, [activeCard]);

  useEffect(() => {
    initialFetchKpis();
  }, [initialFetchKpis]);

  return (
    <KpisUI
      activeCard={activeCard}
      activeDate={activeDate}
      handleActiveDay={handleActiveDay}
      handleRangesDates={handleRangesDates}
      handleActiveCard={handleActiveCard}
      summary={summary}
      branches={branches}
      products={products}
      timeAverages={timeAverages}
      isAdmin={isAdmin}
      onFinishFilterTotalOverview={onFinishFilterTotalOverview}
      summaryTotals={summaryTotals}
      summaryOrders={summaryOrders}
      onFinishFilterDate={onFinishFilterDate}
      summaryHours={summaryHours}
      summaryClients={summaryClients}
      userBranch={user.branch}
    />
  );
};

export default Main;
