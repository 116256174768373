import { Button, Card, DatePicker, Form, Select, Tooltip } from "antd";
import styles from "../styles/Content.module.css";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment"
const data = [
  {
    name: "Enero",
    ventas: 4000,
    ordenes: 2400,
    amt: 2400,
  },
  {
    name: "Febrero",
    ventas: 3000,
    ordenes: 1398,
    amt: 2210,
  },
  {
    name: "Marzo",
    ventas: 2000,
    ordenes: 9800,
    amt: 2290,
  },
  {
    name: "Abril",
    ventas: 2780,
    ordenes: 3908,
    amt: 2000,
  },
  {
    name: "Mayo",
    ventas: 1890,
    ordenes: 4800,
    amt: 2181,
  },
  {
    name: "Junio",
    ventas: 2390,
    ordenes: 3800,
    amt: 2500,
  },
  {
    name: "Julio",
    ventas: 3490,
    ordenes: 4300,
    amt: 2100,
  },
];

const TotalOverview = ({branches,isAdmin,onFinishFilterTotalOverview,summaryTotals,userBranch}) => {
  return (
    <div className={styles.cardContent}> 
      <Form layout="inline" onFinish={onFinishFilterTotalOverview} initialValues={{location:userBranch,year:moment()}} >
        <Form.Item name="location" hidden={!isAdmin}>
          <Select
            size="large"
            className={styles.select}
            showSearch
            optionFilterProp="label" 
            options={branches.map((item) => {
              return { value: item.id, label: item.zone };
            })}
            placeholder="Todas las sucursales"
          />
        </Form.Item>
        <Form.Item name="year">
          <DatePicker.YearPicker size="large" placeholder="Seleccionar año" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            Buscar
          </Button>
        </Form.Item>
      </Form>
      <br />
      <Card className={styles.containerTotalOverviewCard}>
        <p className={styles.legendTotalOverview}>TOTAL ÓRDENES</p>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart width={500} height={500} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Bar
              dataKey="ordenes"
              fill="#FF671D"
              activeBar={<Rectangle fill="#FF671D" stroke="blue" />}
              label={{ position: "centerTop", fill: "#FFF" }}
              color="#FFF"
            />
            <Bar
              dataKey="ventas"
              fill="#585858"
              activeBar={<Rectangle fill="#585858" stroke="blue" />}
              label={{ position: "centerTop", fill: "#FFF" }}
              color="#FFF"
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default TotalOverview;
