import { Card } from "antd";
import styles from "../styles/Content.module.css";
const Summary = ({summary}) => {
  return (
    <div className={styles.cardContent} >
      <Card className={styles.gridCard}>
        <Card.Grid>
          <p className={styles.heading}>56</p>
          <p className={styles.subheading}>TOTAL DE ORDENES ENTREGADAS</p>
        </Card.Grid>
        <Card.Grid>
          <p className={styles.heading}>3</p>
          <p className={styles.subheading}>TOTAL DE ORDENES CANCELADAS</p>
        </Card.Grid>
        <Card.Grid>
          <p className={styles.heading}>0</p>
          <p className={styles.subheading}>PROMEDIO DE ORDENES RECHAZADAS</p>
        </Card.Grid>
        <Card.Grid>
          <p className={styles.heading}>42:12</p>
          <p className={styles.subheading}>TIEMPO PROMEDIO DE ENTREGA</p>
        </Card.Grid>
        <Card.Grid>
          <span className={styles.heading}><p>$</p>9.65</span>
          <p className={styles.subheading}>TICKET PROMEDIO DIARIO</p>
        </Card.Grid>
        <Card.Grid>
          <p className={styles.heading}>34</p>
          <p className={styles.subheading}>PROMEDIO DE ORDENES DIARIAS</p>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default Summary;
